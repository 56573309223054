import { CabIcon, CabTextInput } from "@CabComponents";
import { Alert, Box } from "@mui/material";
import { ReactElement, useState } from "react";
import { Control, Controller, FieldError, FieldValues, Path } from "react-hook-form";
import { IoCheckmarkCircle, IoClose, IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";


const passwordValidation = {
  length: (password: string): boolean => password.length >= 8,
};

const PasswordInput = <FormInput extends FieldValues, >({
  name, control, error, isNew, showSecurityError
}: {
  name: Path<FormInput>,
  control: Control<FormInput>,
  error?: FieldError,
  isNew?: boolean,
  showSecurityError?: boolean,
}): ReactElement => {

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <>
      <Controller name={name} control={control} rules={{ required: true, validate: passwordValidation }} 
        render={({ field: { ref, ...field } }) => (
          <CabTextInput {...field} type={showPassword ? 'text' : 'password'} 
            autoComplete={isNew ? "new-password" : "current-password"} 
            endIcon={
              <CabIcon
                color="black"
                onClick={handleClickShowPassword}
                alt={showPassword ? 'hide password' : 'show password'}
                Icon={showPassword ? IoEyeOffOutline : IoEyeOutline}
              />
            }
          />
        )}
      />
      {error && (
        <Alert severity="error" sx={{ marginTop: 1 }}>
          {error.type === 'required' ? 'Password is required'
            : error.types ? (
              <Box display="flex" flexDirection="column" gap={1}>
                <div>Please choose a password that meets the following criteria:</div>
                <div>
                  <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                    <CabIcon Icon={error.types['length'] ? IoClose : IoCheckmarkCircle } />
                    At least 8 characters long
                  </Box>
                </div>
              </Box>
            ) : null}
        </Alert>
      )}
      {showSecurityError && (
        <Alert severity="error" sx={{ marginTop: 1 }}>
          <Box display="flex" flexDirection="column">
            <Box marginBottom={1}>Your password isn't secure enough - please try the following:</Box>
            <span>• Avoid sequential numbers/letters (e.g. 1234)</span>
            <span>• Avoid repeated characters (e.g. aaaa)</span>
            <span>• Avoid common words (e.g. password123)</span>
            <span>• Include capitals, symbols, and numbers</span>
          </Box>
        </Alert>
      )}
    </>
  );
};

export default PasswordInput;
